import React from 'react';
import propTypes from 'prop-types';

export default function IconSearch({
    width = '24',
    height = '24',
    fill = '#000',
    rotate = '0',
}) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            style={{transform: `rotate(${rotate}deg)`}}>
            <path
                d="M20.2959 22L13.4131 15.1164C10.3513 17.293 6.13218 16.7606 3.70717 13.8916C1.28216 11.0225 1.46022 6.77394 4.1168 4.11783C6.77268 1.46055 11.0219 1.28173 13.8917 3.70647C16.7614 6.13121 17.2942 10.3504 15.1172 13.4123L22 20.2959L20.2971 21.9988L20.2959 22ZM9.2256 4.40924C6.94182 4.40873 4.97151 6.01175 4.50758 8.24778C4.04364 10.4838 5.2136 12.7383 7.30911 13.6463C9.40462 14.5542 11.8497 13.8662 13.1641 11.9986C14.4784 10.1311 14.3008 7.59733 12.7387 5.93145L13.4673 6.65401L12.6459 5.83511L12.6315 5.82066C11.7304 4.91406 10.5039 4.4058 9.2256 4.40924Z"
                fill={fill}
            />
        </svg>
    );
}

IconSearch.propTypes = {
    width: propTypes.string,
    height: propTypes.string,
    fill: propTypes.string,
    rotate: propTypes.string,
};
