globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"dbc9ce43232dca2e85b492736cb41e47af6e049f"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs';

// Only initialize Sentry in the production environment
const isProduction = process.env.NEXT_PUBLIC_APP_ENV === 'production';

if (isProduction) {
    Sentry.init({
        dsn: 'https://89d4b480733edb7ab7e4ee2c47f83599@o317443.ingest.us.sentry.io/4507657908387840',
        // or can use NEXT_PUBLIC_SENTRY_DSN env var

        // Add optional integrations for additional features
        // integrations: [Sentry.replayIntegration()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.01,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.01,
        replaysOnErrorSampleRate: 0.1,

        // ...
        environment: 'production',
        // Note: if you want to override the automatic release value, do not set a
        // `release` value here - use the environment variable `SENTRY_RELEASE`, so
        // that it will also get attached to your source maps
    });
}
