import React from 'react';
import propTypes from 'prop-types';

export default function IconClipboard({
    width = '24',
    height = '24',
    fill = '#000',
    rotate = '0',
}) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            style={{transform: `rotate(${rotate}deg)`}}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.3239 3.83653C17.9656 3.90478 18.5815 4.16237 19.0679 4.59286C19.6453 5.1039 20 5.82761 20 6.61539V19.2028C20 19.9906 19.6453 20.7143 19.0679 21.2253C18.4946 21.7328 17.7411 22 16.9778 22H7.02222C6.25886 22 5.50543 21.7328 4.93206 21.2253C4.35465 20.7143 4 19.9906 4 19.2028V6.61539C4 5.82761 4.35465 5.1039 4.93206 4.59286C5.41846 4.16237 6.03443 3.90478 6.67613 3.83653C6.73467 3.26541 7.05778 2.80681 7.44741 2.50795C7.87821 2.17751 8.43262 2 9.00722 2H14.9928C15.5674 2 16.1218 2.17751 16.5526 2.50795C16.9422 2.80681 17.2653 3.26541 17.3239 3.83653ZM8.51407 3.9625C8.46837 3.99755 8.45061 4.02279 8.44444 4.03463V4.51082C8.45061 4.52266 8.46837 4.5479 8.51407 4.58296C8.60867 4.65551 8.78009 4.72727 9.00722 4.72727H14.9928C15.2199 4.72727 15.3913 4.65551 15.4859 4.58296C15.5316 4.5479 15.5494 4.52266 15.5556 4.51082V4.03463C15.5494 4.02279 15.5316 3.99755 15.4859 3.9625C15.3913 3.88994 15.2199 3.81818 14.9928 3.81818H9.00722C8.78009 3.81818 8.60867 3.88994 8.51407 3.9625ZM16.9524 5.63636C16.8355 5.79088 16.6987 5.92542 16.5526 6.0375C16.1218 6.36795 15.5674 6.54545 14.9928 6.54545H9.00722C8.43262 6.54545 7.87821 6.36795 7.44741 6.0375C7.30129 5.92542 7.16453 5.79088 7.04757 5.63636H7.02222C6.654 5.63636 6.32218 5.767 6.0954 5.96771C5.87265 6.16486 5.77778 6.40164 5.77778 6.61539V19.2028C5.77778 19.4165 5.87265 19.6533 6.0954 19.8505C6.32218 20.0512 6.654 20.1818 7.02222 20.1818H16.9778C17.346 20.1818 17.6778 20.0512 17.9046 19.8505C18.1274 19.6533 18.2222 19.4165 18.2222 19.2028V6.61539C18.2222 6.40164 18.1274 6.16486 17.9046 5.96771C17.6778 5.767 17.346 5.63636 16.9778 5.63636H16.9524Z"
                fill={fill}
            />
        </svg>
    );
}

IconClipboard.propTypes = {
    width: propTypes.string,
    height: propTypes.string,
    fill: propTypes.string,
    rotate: propTypes.string,
};
